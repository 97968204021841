import React from 'react'
import Fade from 'react-reveal/Fade'

import Product from '../Product'

const ProductType = ({ item, activeProduct }) => {
  if (activeProduct !== 'all' && activeProduct !== item.title) return null
  return (
    <div className="mb-15 lg:mb-30">
      {item.title && (
        <Fade ssrReveal delay={250} duration={2250}>
          <h2 className="text-center text-xl lg:text-2xl font-condensed uppercase tracking-wider mb-15 lg:mb-30">
            {item.title}
          </h2>
        </Fade>
      )}

      {item.product.length > 0 && (
        <div className="lg:flex lg:flex-wrap lg:-ml-gs -mb-gs">
          {item.product.map((item, index) => {
            return (
              <Fade
                ssrReveal
                key={index}
                delay={250}
                duration={2250}
                className="h-full"
              >
                <div className="lg:w-1/2 lg:pl-gs pb-gs">
                  {item.product_link.url ? (
                    <a
                      href={item.product_link.url}
                      target="_blank"
                      rel="noreferrer"
                      className="block h-full"
                    >
                      <Product item={item} />
                    </a>
                  ) : (
                    <Product item={item} />
                  )}
                </div>
              </Fade>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ProductType
