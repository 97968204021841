import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Banner from '../components/Banner'
import ProductType from '../components/Product/ProductType'

const BrandsPage = ({ location, data }) => {
  const pageData = data.prismicBrands.data
  const [activeProduct, setActiveProduct] = useState('all')

  return (
    <Layout
      seoTitle={pageData.meta_title || pageData.title}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <Banner title={pageData.title} image={pageData.hero_image} hero />

      {pageData.product_types.length > 0 && (
        <div className="px-ogs">
          <div className="wrapper relative">
            <div className="lg:absolute lg:inset-x-0 lg:w-full z-20">
              <div className="flex items-center justify-center md:justify-end space-x-gs text-xxs uppercase tracking-widest pb-15 lg:pb-30">
                <label htmlFor="filter">Filter</label>
                <div className="select w-32 lg:w-44">
                  <select
                    id="filter"
                    aria-label="Filter"
                    onBlur={e => {
                      setActiveProduct(e.target.value)
                    }}
                    onChange={e => {
                      setActiveProduct(e.target.value)
                    }}
                    className="appearance-none select__inner"
                  >
                    <option value="all">All</option>
                    {pageData.product_types
                      .filter(
                        item =>
                          item.product_type.document !== null &&
                          item.product_type.document.data.title
                      )
                      .map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item.product_type.document.data.title}
                          >
                            {item.product_type.document.data.title}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="relative z-10">
              {pageData.product_types
                .filter(
                  item =>
                    item.product_type.document !== null &&
                    item.product_type.document.data.title
                )
                .map((item, index) => {
                  return (
                    <ProductType
                      key={index}
                      item={item.product_type.document.data}
                      activeProduct={activeProduct}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      )}

      <Banner
        title={pageData.cta_heading}
        image={pageData.cta_image}
        link={pageData.cta_link}
        linkText={pageData.cta_link_text}
      />
    </Layout>
  )
}

BrandsPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default BrandsPage

export const query = graphql`
  query {
    prismicBrands {
      data {
        meta_title
        meta_description
        hero_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        cta_heading
        cta_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        cta_link {
          url
        }
        cta_link_text
        product_types {
          product_type {
            document {
              ... on PrismicProductType {
                id
                data {
                  product {
                    product_link {
                      url
                    }
                    product_image {
                      alt
                      localFile {
                        childImageSharp {
                          fluid(quality: 100, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                    product_heading
                    product_lead {
                      html
                    }
                    product_body {
                      html
                    }
                  }
                  title
                }
              }
            }
          }
        }
        title
      }
    }
  }
`
