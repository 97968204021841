import React from 'react'
import Img from 'gatsby-image'

const Product = ({ item }) => {
  return (
    <div className="sm:flex h-full">
      <div className="sm:w-1/2">
        <div
          className={`bg-white h-full flex flex-col justify-center ${
            item.product_image.localFile ? 'p-2' : 'pb-1:1'
          }`}
        >
          {item.product_image.localFile && (
            <Img
              imgStyle={{
                objectFit: 'contain',
              }}
              className="pb-1:1 h-0 gatsby-image-hide-div"
              fluid={item.product_image.localFile.childImageSharp.fluid}
              alt={item.product_image.alt || item.product_heading}
            />
          )}
        </div>
      </div>
      <div className="sm:w-1/2">
        <div className="bg-grey h-full p-8">
          {item.product_heading && (
            <div className="text-lg uppercase mb-1 font-bold tracking-widest">
              {item.product_heading}
            </div>
          )}

          {item.product_lead.html && (
            <div
              className="rte text-xs opacity-60 mb-4"
              dangerouslySetInnerHTML={{
                __html: item.product_lead.html,
              }}
            />
          )}

          {item.product_body.html && (
            <div
              className="rte"
              dangerouslySetInnerHTML={{
                __html: item.product_body.html,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Product
